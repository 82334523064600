<template>
  <div class="coupon-con">
    <NavBar v-if="isWeixin_status == false" title="优惠券" left-arrow @click-left="goBack"></NavBar>
    <Tabs sticky v-model="active">
      <Tab :title="item" v-for="item in tabs" :key="item">
        <List
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getStatusList"
        >
        <div class="coupon-item" @click="goDetail(item, 2)" v-for="(item, idx) in statusArr" :key="idx">
          <div class="coupon-item-money" :style="item.coupon.type | fontColor">
            <p v-if="item.coupon.type == 1"> <span class="sizeColor"></span>{{item.coupon.m_dec_money | change_num}}</p>
            <p v-if="item.coupon.type == 2">{{item.coupon.zhe_discount | change_num}}</p>
            <p v-if="item.coupon.type == 3"><span class="sizeColor"></span>{{item.coupon.d_face_value | change_num}}</p>
            <span>{{item.coupon.free_type == 1?'随机':''}}{{item.coupon.type | coupon_type}}</span>
          </div>
          <div class="coupon-item-title">
            <p>{{item.coupon.title}}</p>
            <span>{{item.exp_time | change_time}}</span>
            <div class="item-num" v-if="active == 0">
              <span>数量:</span>
              <span> {{item.num}}张</span>
            </div>
          </div>
          <div v-if="active == 0" class="coupon-item-right">
            <!-- <span @click.stop="goDetail(item, 2)">立即使用</span> -->
            <span @click.stop="donateUser(item)">转赠他人</span>
          </div>
          <div v-if="active == 1" class="coupon-item-right get-ticket">
            <span>已使用</span>
          </div>
          <div v-if="active == 2" class="coupon-item-right get-ticket2">
            <span>已过期</span>
          </div>
        </div>
        </List>
      </Tab>
    </Tabs>
    <van-dialog v-model="show" title="请输入手机号码" show-cancel-button @confirm="makePhone">
      <Field
        v-model="phone"
        required
        label="手机号"
        type='number'
        placeholder="请输入手机号"
      />
      <Field
        v-model="send_num"
        required
        label="数量"
        type='number'
        placeholder="请输入转赠数量"
      />
    </van-dialog>
  </div>
</template>
<script>
import { NavBar, Tab, Tabs, List, Field } from "vant";
import CreditShop from "@/api/creditshop";
import UserInfo from "@/api/user";
import {Dialog} from 'vant';
export default {
  components: {
    NavBar,
    Tab,
    Tabs,
    List, 
    Field,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      active: 0,
      listArr: [],
      statusArr: [],
      page: 0,
      loading: false,
      finished: false,
      show: false,
      phone: '',
      item: {},
      send_num: '',
      tabs: ["未使用", "已使用", "已过期"]
    };
  },
  filters: {
    change_time: function(val) {
      return val.substr(3);
    },
    change_num: function(val) {
      if (val.split('.')[1] === '00') {
        return parseInt(val);
      }
      return val;
    },
    choose: function(val) {
      let styleObj = {
        background: `center/100% url(${require("../../../../public/static/images/home/quan_" +
          val +
          ".png")})`
      };
      return styleObj;
    },
    coupon_type: function(val) {
      switch (val) {
        case 1:
          return '满减券'
        case 2:
          return '折扣券'
        case 3:
          return '代金券'
        case 4:
          return '兑换券'
        default:
          break;
      }
    },
    fontColor: function(val) {
      let fontColor = { color: "#46c0b4" };
      switch (val) {
        case 1:
          fontColor.color = "#26c5c3";
          break;
        case 2:
          fontColor.color = "#26c56d";
          break;
        case 3:
          fontColor.color = "#c59626";
          break;
        case 4:
          fontColor.color = "#269ec5";
          break;
        case 5:
          fontColor.color = "#2653c5";
          break;
        case 6:
          fontColor.color = "#c526bf";
          break;
        case 7:
          fontColor.color = "#c5262a";
          break;
        default:
          break;
      }
      return fontColor;
    }
  },
  watch: {
    active: function() {
      this.statusArr = [];
      this.page = 0;
      this.getStatusList();
    }
  },
  methods: {
    donateUser(item) {
      this.show = true;
      this.item = item;
    },
    async makePhone() {
      try {
        if (!this.phone) return this.$toast('请输入手机号码');
        if (this.phone.length > 11) {
          this.phone = '';
          return this.$toast('请输入正确手机号码');
        }
        if (!this.send_num) return this.$toast('请输入转赠数量');
        const res = await UserInfo.moveUserCoupon(this.item.id, this.phone, this.send_num);
        if (res.code == 200) {
          this.statusArr.forEach((el) => {
            if (el.id == this.item.id) {
              el.num = el.num - this.send_num
            }
          });
        }
        this.$toast(res.msg);
        this.phone = '';
        this.send_num = '';
      } catch (error) {
        console.log(error)
      }
    },
    async goDetail(item, type) {
      this.$router.push("/cpn_detail?id=" + item.id + "&type=" + type);
    },
    async getStatusList() {
      try {
        let obj = {};
        this.$toast.loading('加载中~');
        switch (this.active) {
          case 0:
            obj.is_used = 0;
            obj.is_exp = 0;
            break;
          case 1:
            obj.is_used = 1;
            break;
          case 2:
            obj.is_used = 0;
            obj.is_exp = 1;
            break;
          default:
            break;
        }
        const res = await CreditShop.getScreen(obj.is_used, obj.is_exp, this.page);
        if (res.code == 200) {
          if (res.data.result.length > 0) {
            for (let i in res.data.result) {
              this.statusArr.push(res.data.result[i]);
            }
            this.loading = false;
            if (this.statusArr.length == res.data.total_num) {
              this.finished = true;
            }
          } else {
            throw('暂无数据...')
          }
          this.$toast.clear();
        } else {
          throw res.msg;
        }
      } catch (error) {
        this.loading = false;
        this.finished = true;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.coupon-con {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background: rgba(244, 244, 244, 1);
  .coupon-item {
    position: relative;
    margin: 10px auto 0;
    width: 96%;
    border-radius: 3px;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    align-items: center;
    .coupon-item-money {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: 0;
      bottom: 0;
      color: #fb0;
      height: 100px;
      width: 101px;
      p {
        flex-wrap: 700;
        font-size: 30px;
      }
      .sizeColor:before {
        position: relative;
        bottom: 1px;
        left: 3px;
        font-size: 20px;
        content: "\FFE5";
      }
    }
    .item-num {
      font-size: 12px;
      margin-right: 18px;
      margin-top: 5px;
      span:nth-child(1) {
        font-weight: 400;
      }
    }
    .coupon-item-title {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #151516;
      font-size: 14px;
      p {
        position: relative;
        max-width: 150px;
        line-height: 20px;
        font-size: 15px;
        word-wrap: break-word;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        margin-bottom: 2px;
      }
    }
    .coupon-item-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      position: relative;
      width: 76px;
      height: 100px;
      text-align: center;
      padding-right: 16px;
      span {
        display: block;
        font-size: 12px;
        width: 60px;
        height: 30px;
        border-radius: 4px;
        line-height: 30px;
        color: #fff;
        background: #fb0;
      }
      span:nth-child(2) {
        margin-top: 10px;
        color: #fff;
        background: #64d878;
      }
    }
    .get-ticket {
      span {
        background: #f56c6c;
      }
    }
    .get-ticket2 {
      span {
        background: #909399;
      }
    }
  }
  .coupon-item:before, .coupon-item:after {
    position: absolute;
    background-color: #f4f4f4;
    width: 14px;
    height: 14px;
    top: 43px;
    content: "";
  }
  .coupon-item:before {
    border-radius: 0 14px 14px 0;
    left: -8px;
  }
  .coupon-item:after {
    border-radius: 14px 0 0 14px;
    right: -8px;
  }
}
</style>
